.search_container {
  background-color: rgba(30, 30, 30, 0.4);
  background-size: 95%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88vh;
  flex-direction: column;

  & > div {
    z-index: 3;
  }

  &-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.search-title {
  color: white;
  font-size: 45px;
  margin-bottom: 2%;
  text-align: center;
  font-weight: 500;
  text-shadow: 5px 5px 5px rgba(30, 30, 30, 0.4);
}

.search_space {
  background-color: white;
}

.search-button {
  background-color: #27ae60 !important;
  border: none !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: white !important;
  width: 100%;
}


.search-button:hover {
  background-color: #218c53 !important;
}

@media (max-width: 767px) {
  .search-title {
    font-size: 24px;
  }

  .search-button {
    font-size: 16px !important;
  }
}
