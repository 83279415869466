.carousel-container {
  height: 100%;
  width: 100%;
}

.court-container {
  background-color: white;
}

.object-contain {
  object-fit: contain;
}

.object-center {
  object-fit: center;
}

.object-cover {
  object-fit: cover;
}

/* MAPA */

.court-info-map {
  height: 0;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  position: relative;
  width: 100%;
}

.court-info-map iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Media Queries */

@media (max-width: 768px) {
  .court-image {
    flex-direction: column;
  }

  .court-image-title {
    font-size: 30px;
  }

  .court-image-name {
    font-size: 24px;
  }

  .court-image-location {
    font-size: 14px;
  }

  .court-info {
    flex-direction: column;
  }

  .court-info-map-hour {
    width: 100%;
  }

  .button-panel button {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .court-image {
    flex-direction: column;
  }

  .court-image-title {
    font-size: 20px;
  }

  .court-image-name {
    font-size: 18px;
  }

  .court-image-location {
    font-size: 12px;
  }

  .court-info {
    flex-direction: column;
  }

  .court-info-map-hour {
    width: 100%;
  }

  .button-panel button {
    font-size: 10px;
  }
}

.court-splider-image,
.card-img-top {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.btn-calificar {
  background-color: rgba(240, 238, 255, 1);
  color: rgba(95, 75, 219, 1);
  border: none;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.btn-reservar {
  background-color: rgba(39, 174, 96, 1);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.btn-reservar:hover {
  background-color: rgba(39, 174, 96, 0.8);
}

.btn-reservar:active {
  background-color: #1b7c44;
}

.btn-reservar-active {
  background-color: #1b7c44;
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.btn-cancelar {
  background-color: rgba(133, 133, 133, 0.57);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.position-absolute-pill-court {
  position: absolute;
  bottom: -25px;
  right: calc(100% - 98%);
}

.grid-container-court {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}

.grid-item-court {
  padding: 10px 0;
  font-size: 16px;
  text-align: right;
  font-weight: 600;
}

.grid-item-court:nth-child(odd) {
  text-align: left;
  font-weight: 400;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
}

.color-hr {
  background-color: rgb(39, 174, 96);
  height: 3px;
  border: none;
  opacity: 1;
}

/* .t-maximo{

  max-height: 400px;
} */

@media (max-width: 768px) {
  .responsive-iframe {
    width: 100%;
    min-height: 300px;
  }
}

@media (max-width: 768px) {
  .position-absolute-pill-court {
    bottom: -20px;
  }
}

.button-panel .btn {
  flex: 1;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.button-panel .btn:hover:not(:disabled) {
  background-color: rgba(0, 217, 95, 1);
}

.button-panel .button-panel-disabled {
  background-color: #d9534f !important;
  color: white !important;
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}

.button-panel .button-panel-active {
  background-color: #1b7c44 !important;
  color: white !important;
}

.nonselected-msg {
  min-height: 200px;
}

.vanilla-calendar .vanilla-calendar-day__btn_selected,
.vanilla-calendar .vanilla-calendar-years__year_selected,
.vanilla-calendar .vanilla-calendar-months__month_selected {
  background-color: green !important;
  color: white !important;
}

.vanilla-calendar .vanilla-calendar-day__btn_weekend.vanilla-calendar-day__btn_selected {
  background-color: #d9534f !important;
}

.vanilla-calendar .vanilla-calendar-day__btn_today {
  color: green !important;
}

.vanilla-calendar .vanilla-calendar-day__btn_today.vanilla-calendar-day__btn_selected {
  background-color: green !important;
  color: white !important;
}

.vanilla-calendar .vanilla-calendar-day__btn_weekend.vanilla-calendar-day__btn_today.vanilla-calendar-day__btn_selected {
  background-color: #d9534f !important;
  color: white !important;
}

.success-circular-progress .MuiCircularProgress-circle {
  stroke: #4caf50 !important;
}

.ultimas-valoraciones-title {
  color: black;
}

.badge-color {
  background-color: rgba(133, 133, 133, 1);
}
