$purple: #8743DF;
$purple-rgb: 135, 67, 223;
$purple-light: lighten($purple, 20%);
$purple-dark: darken($purple, 20%);

$green: #2BC155;
$green-rgb: 43, 193, 85;

$orange: #FBA556;
$orange-rgb: 251, 165, 86;

