.mail-icon {
  width: 17px;
  height: 17px;
}

.login-container{
  padding-top: 2em;
  padding-bottom: 2em;
}

.login-button {
  background-color: #007bff;
}

.login-button:hover {
  background-color: #0056b3;
}


.login-extra-below {
  font-size: 12px;
}

.container-login {
  max-width: 600px !important;
}

.fs-custom {
  font-size: 10px;
}

@media screen and (max-width: 600px) {
  .fs-custom {
    font-size: 14px;
  }

}


.custom-google-button {
  background-color: #4285F4; /* Google blue */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-google-button:hover {
  background-color: black;
}

.custom-google-button:disabled {
  background-color: #cccccc;
  cursor: default;
}
