.form-control {
  font-size: 90%;
}

.aboutme {
  font-size: 14px;
}

.visits-pill {
  background-color: #ECF0FF;
  color: #2D9CDB;

}

.moredetails-pill {
  background-color: transparent;
  color: #363848;
  border: 1px solid #EDEDED;

}

.moredetails-pill:hover {
  background-color: #f1f3f5
}

.stats-item {
  display: flex;
  align-items: center;
}

.stats-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.stats-label {
  font-size: 12px;
}

.profile-icon-container {
  background-color: #8743DF;
  color: white;
}

.review-icon-container {
  background-color: #FFBE17;
  color: white;
}

.location-icon-container {
  background-color: #ECECEC;
  color: #808080;
}


.profile-user-name-wrapper {
  max-width: 200px;
}

.group-pill {
  width: 200px;
}

.user-image-profile {
  max-width: 150px;
  max-height: 150px;
}

.btn-cancelar:disabled {
  cursor: not-allowed;
}

.btn-reservar:disabled {
  cursor: not-allowed;
}