.footer {
  background-color: rgba(31, 33, 49, 1);
  color: #fff;
}




.footer-logo-name {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.social-media {}

.social-media-logo {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.contact-link {
  color: white;
}

.reservados-section {
  border-top: 1px solid #3D3F4E;
  background-color: #1A1C28;
}

@media screen and (max-width: 768px) {



  .social-media-logo {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {


  .social-media-logo {
    width: 30px;
    height: 30px;
  }
}