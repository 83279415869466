.carousel-item-fixed {
    height: 150px !important;
}

.carousel-item-fixed img {
    height: 100% !important; 
    width: 100% !important;
    object-fit: cover !important; 
}





@media (max-width: 767px) {
    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }

    .court-carousel-large .carousel-item-fixed {
        height: 300px !important; /* Ajusta la altura para dispositivos móviles si es necesario */
    }

    .court-carousel-small .carousel-item-fixed {
        height: 200px !important; /* Ajusta la altura para dispositivos móviles si es necesario */
    }
}
