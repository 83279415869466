.navbar {
  background-color: #FFFFFF !important;
}

.nav-user {
  display: flex;
  align-items: center;
}

.user-image {
  max-width: 40px;
  max-height: 40px;
}

.user-name-wrapper {
  display: flex;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-name {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .nav-user {
    padding-right: 10px;
  }

}

.search-bar-autocomplete-navbar {
  border: none !important;
  max-width: 250px;
}

@media (max-width: 575.98px) {
  .search-bar-autocomplete-navbar {
    width: 350px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .search-bar-autocomplete-navbar {
    width: 250px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .search-bar-autocomplete-navbar {
    width: 200px;
  }
}

@media (min-width: 1401px) {
  .search-bar-autocomplete-navbar {
    width: 375px;
  }
}

.nav-link.active {
  background-color: #76B72D42;
  color: black !important;
}

.nav-link:hover {
  background-color: #76B72D42;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.search {
  background: none;
  border: none !important;
}

.search:hover,
.search:focus,
.search:active {
  background-color: #f1f3f5;
}