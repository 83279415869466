.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-rate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding: 20px;
  width: 50%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.btn-cargar {
  background-color: rgba(48, 199, 54, 1);
  border: none;
  font-size: 18px;
  font-weight: 600;


}

.sin-border {
  border-bottom: none !important;
  border-top: none !important;

}


.text-area-custom {
  border: 1px solid #C2C2C2 !important;
  resize: none !important;
}