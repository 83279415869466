.places-container {
  background-color: rgba(240, 240, 240, 1);

}

.places-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.place-info {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.place-info-content {
  text-align: center;
  align-items: center;
}

.place-info-name {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.place-info-note {
  font-size: 18px;
  color: #000000;
  text-align: center;
}

/* Detalles Grilla */
.grid-container-places {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}

.grid-item-places {
  padding: 10px 0;
  font-size: 16px;
  text-align: right;
  font-weight: 600;
}

.grid-item-places:nth-child(odd) {
  text-align: left;
}

.btn-reservar-places {
  background-color: #2F80ED;
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.state-container img {
  width: 50%;
}

.position-absolute-pill-places {
  position: absolute;
  bottom: -22px;
  right: calc(100% - 82.5%);
}


.card-footer-custom {
  background-color: inherit !important;
  border-top: none !important;
}

.card-footer-custom-places {
  background-color: inherit !important;
  border-top: none !important;
  max-height: 75px;
}

.btn-cancelar-places {
  background-color: #f8f4f4;
  color: black;
  border: 1px solid rgba(133, 133, 133, 0.57);
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 769px) and (max-width: 1280px) {
  .places-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .position-absolute-pill-places {
    bottom: -20px;
    right: calc(100% - 80%);
  }
}

@media (max-width: 768px) {
  .places-grid {
    grid-template-columns: 1fr;
  }

  .position-absolute-pill-places {
    bottom: -20px;
    right: calc(100% - 80%);
  }
}

.card-custom-places {
  overflow: hidden;
  word-wrap: break-word;
}

.list-group-item-places {
  padding: 0.5rem 0px !important;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .place-info-name {
    font-size: 22px;
  }

  .list-group-item {
    font-size: 14px;
  }

  .btn-reservar {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }

  .btn-cancelar {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}
