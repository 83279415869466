.ActiveCourt {
    background-color: rgba(48, 199, 54, 1);
}

.InactiveCourt {
    background-color: rgb(241, 5, 5);
}

.badge2 {
    margin-top: -30px !important;
    z-index: 20 !important;
    position: relative;
}

.pill-text {
    font-size: 1.3rem;
}

@media (min-width: 768px) {
    .text {
        font-size: 0.1rem;
    }
}

@media (min-width: 768px) and (max-width: 1399px) {
    .pill-text {
        font-size: 0.75rem;
    }
}

@media (max-width: 767px) {
    .pill-text {
        font-size: 1.3rem;
    }
}