.court-container {
  background-color: white;
}

.image-court {
  height: auto;
  width: 100%;
}

.value-text {
  font-size: 1.225rem;
}

.court-image-name {
  font-size: 36px;
  font-weight: 600;
}



/* modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-rate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding: 20px;
  width: 50%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



.sin-border {
  border-bottom: none !important;
  border-top: none !important;

}

.modal-text {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}
