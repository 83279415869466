.txt-leermas {
  color: #1b7c44 !important;
}

.btn-link {
  color: #1b7c44 !important;
}

.rating-carousel .splide__arrow {
  background-color: green !important;
  border: none;
  color: white;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  opacity: 1;
}

.rating-carousel .splide__arrow--prev {
  left: -20px; /* Ajusta la posición hacia la izquierda */
}

.rating-carousel .splide__arrow--next {
  right: -20px; /* Ajusta la posición hacia la derecha */
}

.rating-carousel .splide__arrow:hover {
  background-color: #006400; /* Fondo verde oscuro en hover */
  opacity: 1; /* Totalmente opaco al pasar el mouse */
}

.rating-carousel .splide__arrow svg {
  width: 20px;
  height: 20px;
  fill: white; /* Asegura que el SVG sea blanco */
}

.rating-container {
  background-color: white;
}
