.star {
  width: 25px;
  height: 25px;
  margin: 0 2px;
  transition: fill 0.2s ease-in-out;
}

.interactive {
  cursor: pointer;
}

.star-empty {
  opacity: 0.2;  
}

@media (max-width: 768px) {
  .star {
      width: 25px;
      height: 25px;
  }
}

@media (max-width: 480px) {
  .star {
      width: 17.5px;
      height: 17.5px;
  }
}
