/* Principales */
.presentation-box {
  background-color: rgba(240, 240, 240, 1);
}

.stat-image {
  width: 60px;
  height: auto;
}

/* Reservacion */

.reserv-title {
  font-size: 24px;
}

.reservation-info {
  background-color: rgba(255, 255, 255, 1);
  font-size: 18px;
  overflow-x: auto;
}

.reservation-info-img {
  height: auto;
  width: auto;
  align-self: center;
}

.reservation-info-title {
  font-size: 18px;
}

.reservation-info-name {
  font-size: 16px;
  color: rgba(95, 75, 219, 1);

}

.reservation-details {

  font-size: 16px;
}

@media (max-width: 670px) {

  .reservation-info-value,
  .reservation-info-ubi {
    display: none;
  }
}

@media (max-width: 430px) {}


/* BOTONES */

.reservation-info-buttons {
  display: flex;
  justify-content: flex-end;
}

.reservation-info-buttons button {
  height: 50px;
}

.reservation-info-buttons button:hover {
  transform: scale(1.1);
  transition: transform 0.3s;
}

.reservation-info-buttons button img {
  height: 50px;
  width: 50px;
}

.button-placeholder {
  min-width: 80px;
  min-height: 30px;
}

.btn-purple {
  background-color: rgba(240, 238, 255, 1);
  color: rgba(95, 75, 219, 1) !important;
  font-weight: 600;
  font-size: 16px;
}

.btn-purple:hover {
  background-color: rgba(95, 75, 219, 1);
  color: white !important;
}

.btn-heart {
  background-color: #FFD9D9;
  color: #FF5353 !important;
  font-weight: 600;
  font-size: 16px;
}

.btn-heart:hover {
  background-color: #FF5353;
  color: white !important;
}

.btn-arrow {
  background-color: transparent;
  color: #BDBDBD !important;
  font-weight: 600;
  font-size: 16px;
}


.btn-arrow:hover {
  background-color: #f1f3f5;
}

.subtitle-tabla {
  font-size: .75em;
  font-weight: 400;
}

.calendario-container {
  background-color: #5F4BDB;
  color: white;
}

.valor-container {
  background-color: #2BC155;
  color: white;
}

.ubicacion-container {
  background-color: #FBA556;
  color: white;
}


/* Media queries */
@media (max-width: 1150px) {
  .reservation-info-buttons {
    display: none;
  }
}

.stat-image {
  max-width: 100%;
  height: auto;
}

.text-valor {
  font-size: 2rem;
  font-weight: 600;
}

.text-subtitulo {
  color: #6c757d;
  font-size: 13px;
}

.bg-clubes {
  background-image: url('assets/images/clubes.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--bs-card-border-radius);
  }
}

.bg-canchas-activas {
  background-image: url('assets/images/can-activas.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  flex: 1;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--bs-card-border-radius);
  }
}

.bg-duracion-total {
  background-image: url('assets/images/dur-total.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  flex: 1;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--bs-card-border-radius);
  }
}

.bg-retos-completados {
  background-image: url('assets/images/retos-com.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  flex: 1;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: var(--bs-card-border-radius);
  }
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: relative;
  padding: 0;
  color: white;
}


@media (max-width: 1199.98px) {

  .reservation-details,
  .reservation-info-title,
  .reservation-info-name {
    text-align: center;
  }

  .reservation-info-buttons {
    justify-content: center;
  }
}
