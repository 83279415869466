.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  position: relative;
  border: 3px dotted #ccc;
  box-sizing: border-box;
}

.inputfile+label .file-type {
  font-size: 0.75em;
  font-weight: normal;
  margin-top: 0.5em;
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: #f1f3f5;
}

.file-info {
  border-color: #28a745 !important;
}

.file-size {
  color: #28a745;
}

.placeholder-file-upload {
  max-width: 55px;
  max-height: 55px;
}