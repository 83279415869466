.tabla-wrapper {
    background-color: white;

}

.filter-component {
    width: 100%;
    max-width: 350px;
}

.close-icon-green {
    color: green;
}

.tabla-wrapper div:last-child {
    background-color: unset !important;
}