.register-button {
  background-color: rgba(39, 174, 96, 1);
  cursor: pointer;
  font-size: 16px;
}

.register-button:hover {
  background-color: #0056b3;
}

.form-input {
  border: 1px solid rgba(185, 185, 185, 1);
  box-sizing: border-box;
  font-weight: 300;
}

.container-register {
  max-width: 700px !important;
}
