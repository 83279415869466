.btn-reservar {
  background-color: rgba(39, 174, 96, 1);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.btn-reservar:disabled {
  background-color: rgb(189, 189, 189); /* Gris claro */
  color: rgba(255, 255, 255, 0.34); /* Texto opaco */
  cursor: not-allowed; /* Indicador visual de no disponible */
  pointer-events: none; /* Desactiva clics */
}

.btn-reservar:hover {
  background-color: rgba(39, 174, 96, 0.8);
  color: rgb(255, 255, 255);

}

.btn-reservar:active {
  background-color: #1B7C44;
}

.btn-reservar-active {
  background-color: #1B7C44;
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}


.payment-option.selected {
  border: 2px solid #007bff;
  background-color: #f0f8ff;
}

.payment-option:hover {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}
