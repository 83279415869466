.btn-heart {
  background-color: #FFD9D9;
  color: #FF5353 !important;
  font-weight: 600;
  font-size: 16px;
}

.btn-reservar {
  background-color: rgba(39, 174, 96, 1);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.btn-reservar:hover {
  background-color: rgba(39, 174, 96, 0.8);
}

.btn-reservar:active {
  background-color: #1B7C44;
}

.btn-reservar-active {
  background-color: #1B7C44;
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.object-contain {
  object-fit: contain;
}

.object-center {
  object-fit: center;
}

.object-cover {
  object-fit: cover;
}
