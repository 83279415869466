@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./styles/colors";

:root {
  --purple: $purple;
  --purple-rgb: $purple-rgb;
  --purple-light: $purple-light;
  --purple-dark: $purple-dark;

  --green: $green;
  --green-rgb: $green-rgb;

  --orange: $orange;
  --orange-rgb: $orange-rgb;
}

body {
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-image-container {
  background-image: url("assets/images/background-img.webp");
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.login-logo {
  width: 150px;
  height: auto;
}

.social-icon {
  width: 45px;
  height: 45px;
  transition: transform 0.2s ease-out;
}

.social-icon:hover {
  transform: scale(1.1);
}

.dest {
  color: rgba(10, 105, 48, 1);
  text-decoration: none;
  font-weight: 900;
}

.dest-user {
  color: rgba(10, 105, 48, 1);
  text-decoration: none;
  font-size: 25px;
  font-weight: 900;
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 26px;
}


@media (max-width: 450px) {
  .social-icon {
    width: 35px;
    height: 35px;
  }
}

.MuiInput-underline::before {
  border-bottom: unset !important;
}

.MuiInput-underline::after {
  border-bottom: unset !important;
}

table.table-separated {
  border-collapse: separate;
  border-spacing: 0 1em;

  & tr {
    background-color: unset !important;
  }

  & td {
    /*background-color: unset !important;*/
    border-bottom: none !important;
    /*min-width: 125px;*/
  }

  & td:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    min-width: 75px;
  }

  & td:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
}

/* Emulate the md- from tailwind for making things like .hidden.md-block */
.d-hidden {
  display: none;
}

.d-block {
  display: block;
}

.d-table-row-group {
  display: table-row-group;
}

@media (min-width: 768px) {
  .md-d-block {
    display: block !important;
  }

  .md-d-table {
    display: table !important;
  }

  .md-d-table-row-group {
    display: table-row-group !important;
  }

  .md-d-hidden {
    display: none !important;
  }
}

.border-x {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}


.form-control:focus{
  border-color: #219653 !important;
  box-shadow: none !important;
}

.bg {
  &-purple {
    background-color: $purple;

    &-light {
      background-color: $purple-light;
    }
  }

  &-green {
    background-color: $green;
  }

  &-orange {
    background-color: $orange;
  }
}
